<template>
  <div class="home">
    <div v-if="loading" class="spin-loading">
      <a-spin tip="Loading..." size="large">
        <div class="spin-content"></div>
      </a-spin>
    </div>
    <div v-else>
    <div class="rotation">
      <a-carousel autoplay>
        <div v-for="(item,index) of rotationImg" :key="index"><img style="width:100%;height:900px;object-fit:cover" :src="item.url" alt=""></div>
      </a-carousel>
      <div class="bgcolor"></div>
    </div>
    <div class="home_content">
      <div class="news">
        <div class="tips">
          <div class="title">最新新闻</div>
          <!-- <div @click="changeMore('consortium','1','0')" class="more">查看更多>></div> -->
        </div>
        <div class="items" v-if="newsData.length != 0">
          <div class="list" v-for="(item,index) of newsData" :key="index" @click="changeDetails(item)">
            <div class="Img">
              <img :src="item.cover" alt="">
            </div>
            <div class="message">
              <div class="title1">{{item.title}}</div>
              <div class="title2" v-html="item.content">{{item.content}}</div>
              <div class="time">{{moment(item.pubdate).format('YYYY-MM-DD')}}</div>
            </div>
          </div>
        </div>
        <div v-else style="text-align:center;margin-top:20px;">
          <img src="../../assets/nodata.jpg" alt="">
          <div>暂无文章</div>
        </div>
      </div>
      <div class="notice">
        <div class="tips">
          <div class="title">通知公告</div>
          <div @click="changeMore('list','1','2','notice')" class="more">查看更多>></div>
        </div>
        <div class="noticeList" v-if="noticesData.data.length != 0">
          <div class="list" v-for="(item,index) of noticesData.data" :key="index" @click="changeDetails(item)">
            <div class="title1">{{item.title}}</div>
            <div class="title2" v-html="item.content">{{item.content}}</div>
            <div class="time">{{moment(item.pubdate).format('YYYY-MM-DD')}}</div>
          </div>
        </div>
        <div v-else style="text-align:center;margin-top:20px;">
          <img src="../../assets/nodata.jpg" alt="">
          <div>暂无文章</div>
        </div>
      </div>
    </div>

    <div class="home_content">
      <div style="width:100%">
        <div class="tips">
          <div class="title">联合体项目</div>
          <div @click="changeMore('list','2','0','project')" class="more">查看更多>></div>
        </div>
        <div class="items" v-if="ConsortiumProjectData.data.length !=0">
          <div class="list" v-for="(item,index) of ConsortiumProjectData.data" :key="index" @click="changeDetails(item)">
            <div class="Img">
              <img :src="item.cover" alt="">
            </div>
            <div class="message">
              <div class="title1">{{item.title}}</div>
              <div class="title2" v-html="item.content">{{item.content}}</div>
              <div class="time">{{moment(item.pubdate).format('YYYY-MM-DD')}}</div>
            </div>
          </div>
        </div>
        <div v-else style="text-align:center;margin-top:20px;">
          <img src="../../assets/nodata.jpg" alt="">
          <div>暂无文章</div>
        </div>
      </div>

    </div>


     <div class="home_content" style="display:block">
       <div class="tips">
          <div class="title">联合体成员</div>
          <div @click="changeMore('company','4','3')" class="more">查看更多>></div>
        </div>
        <div class="solve_box"> 
          <!-- 当数量需要滚动时使用轮播组件 -->
          <a-carousel arrows autoplay v-if="memberList.length > 1">
            <div slot="prevArrow" class="custom-slick-arrow" style="left: 0;z-index:999">
              <a-icon type="left-circle" />
            </div>
            <div slot="nextArrow" class="custom-slick-arrow" style="right: 0;z-index:999" >
              <a-icon type="right-circle" />
            </div>
            <div v-for="(item,index) of memberList" :key="index">
              <div style="display:flex; flex-wrap:wrap;justify-content:flex-start;max-width:1350px;margin:0 25px;">
                <div class="list" v-for="(item2,index2) of item" :key="index2" @click="changeDetails(item2,'member')">
                  <div class="Img"><img :src="item2.logo_url" alt=""></div>
                  <div class="title">{{item2.name}}</div>
                </div>
              </div>
            </div>
          </a-carousel>
          <!-- 不需要轮播时使用自定义展示 -->
          <div v-else style="margin-top:40px;">
              <div style="display:flex; flex-wrap:wrap;justify-content:flex-start;max-width:1350px;margin:0 25px;">
                  <div class="list" v-for="(item2,index2) of memberList[0]" :key="index2" @click="changeDetails(item2,'member')">
                    <div class="Img"><img :src="item2.logo_url" alt=""></div>
                    <div class="title">{{item2.name}}</div>
                  </div>
                </div>
            </div>
        </div>

    </div>

    </div>
    
  </div>
</template>
<script>
import moment from "moment";
export default {
  data(){
    return{
      moment,
      rotationImg:[],

  // img:require('../assets/solve/2.jpg')
      newsData:[], // 新闻数据
      noticesData:{
        data:[],
      }, // 通知公告数据
      ConsortiumProjectData:{ // 联合体项目数据
        data:[],
      },
      memberList:[],  // 联合体成员

      loading:true,
    }
  },
  methods:{
    // 跳转详情查看
    changeDetails(data,value){
      let query = {};
      query.isTop = 'Top';
      query.detailID = data.id;
      if(value == 'member'){
        query.memberID = data.id;
        delete query.detailID;
      }
      this.$router.push({path:'messageDetails',query:query});
    },
    // 查看更多
    changeMore(url,index,index2,value){
      let query = {};
      if(value == 'notice'){
        query.id = this.noticesData.column_id;
      }else if(value == 'project'){
        query.id = this.ConsortiumProjectData.column_id;
      }
      this.$router.push({path:url,query:query});
      localStorage.setItem('showIndex',index);
      localStorage.setItem('secondIndex',index2);
    },
    // 首页轮播图
    getBanner(){
      this.$get('/v1/banner').then(res=>{
        this.rotationImg = res;
        setTimeout(() => {
          this.loading = false
        }, 500);
      })
    },
    // 获取最新新闻数据
    getNewsData(){
      this.$get('/v1/news/lastnews').then(res=>{
        res.forEach(item=>{
          if(item.content){
            item.content = item.content.replace(/<\/?(img)[^>]*>/gi, '').replace(/<\/?(iframe)[^>]*>/gi,'').replace(/<\/?(video)[^>]*>/gi,'');
          }
        })
        this.newsData = res;
      })
    },
    // 获取公告内容数据
    getNoticesData(){
      this.$get('/v1/news/lastboardcase').then(res=>{
        res.data.forEach(item=>{
          if(item.content){
            item.content = item.content.replace(/<\/?(img)[^>]*>/gi, '').replace(/<\/?(iframe)[^>]*>/gi,'').replace(/<\/?(video)[^>]*>/gi,'');
          }
        })
        this.noticesData = res;
      })
    },
    // 获取联合体项目是数据
    getConsortiumProject(){
      this.$get('/v1/news/lastunion').then(res=>{
        res.data.forEach(item=>{
          if(item.content){
            item.content = item.content.replace(/<\/?(img)[^>]*>/gi, '').replace(/<\/?(iframe)[^>]*>/gi,'').replace(/<\/?(video)[^>]*>/gi,'');
          }
        })
        this.ConsortiumProjectData = res;
      })
    },
    // 获取联合体成员
    getConsortiumMember(){
      this.$get('/v1/member/index').then(res=>{
        if(res.data.length != 0){
          this.memberList = this.group(res.data,8);
        }
      })
    },
    // 将数组分成若干小数据
    group(array, subGroupLength) {
      let index = 0;
      let newArray = [];
      while(index < array.length) {
          newArray.push(array.slice(index, index += subGroupLength));
      }
      return newArray;
    }
  },
  mounted() {
    this.getBanner();
    this.getNewsData();
    this.getNoticesData();
    this.getConsortiumProject();
    this.getConsortiumMember();
  },
}

</script>

<style scoped lang="scss">
  .rotation{position: relative;
    .bgcolor{height: 226px;position: absolute;top: 0;left: 0;z-index: 10;width: 100%;background-image: url('../../assets/mask.png');}
  }
  .ant-carousel >>> .slick-slide {
  text-align: center;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
}
  .home{
    .home_content{max-width: 1400px;margin:0 auto;display:flex;justify-content:space-between;margin-top:30px;
      .news{max-width: 1000px;width: calc(70% - 15px);  }
        .items{border: 1px solid #ececec;padding: 12px;margin-top:15px;
          .list{display: flex;border-bottom:1px solid #ececec;margin-bottom:15px;padding-bottom:15px;height:146px;cursor: pointer;
            img{width: 200px;height: 130px;}
            .message{margin-left: 30px;display: flex;flex-direction: column;justify-content: space-between;
              
            }
          }
          .list:last-child{margin-bottom:0;border-bottom:0;padding-bottom: 0;height: 130px;}
        }
    
      .notice{width: calc(30% - 10px);
        .noticeList{padding: 12px;border:1px solid #ececec;margin-top: 15px;
          .list{border-bottom:1px solid #ececec;margin-bottom:15px;padding-bottom:15px;height:146px;display: flex;flex-direction: column;justify-content: space-between;cursor: pointer;}
          .list:last-child{margin-bottom:0;border-bottom:0;padding-bottom: 0;height: 130px;}
        }
      }
      .tips{display: flex;justify-content: space-between;align-items:center;border-bottom:1px solid #e6e6e6;
        .title{font-size: 22px;border-bottom: 4px solid #333;}
        .more{font-size: 14px;cursor: pointer;}
      }
      .title1{font-size: 20px;font-weight: bold;color: #333;word-break:break-all;text-overflow: -o-ellipsis-lastline;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;line-clamp: 1;-webkit-box-orient: vertical;}
      .title2,.time{font-size: 12px;color: #333;}
      .title2{word-break:break-all;text-overflow: -o-ellipsis-lastline;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;line-clamp: 2;-webkit-box-orient: vertical;height: 36px;overflow: hidden;}
    }
  }



  .solve_box{
width:100%;margin:0px auto;
  .IconList{
   width:calc(25% - 20px);cursor: pointer;
    height:110px;margin:9px;display:flex;justify-content:center;
    img{height: 100%;}
  }
  .list{width: calc(25% - 30px);text-align: center;margin:0 15px 15px 15px;border:1px solid #ececec;border-radius:4px;cursor: pointer;
          .Img{height: 100px;position: relative;
            img{height: 100%;max-width: 100%;position: absolute;top: 0;right: 0;bottom: 0;left: 0;margin: auto;}
          }
          .title{background: #f5f5f5;font-size: 12px;color: #252629;line-height: 24px;}
        }
}



</style>
<style scoped>
.solve_box .ant-carousel >>> .slick-slide{
  height: 310px;line-height: 310px;
}
/* For demo */
.ant-carousel >>> .slick-slide {

  /* background: #364d79; */
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;

  border-radius: 50%;
  /* border:solid 1px #e2e2e2; */
  opacity: 1;
  text-align: center;
  line-height: 40px;
  color: #e2e2e2;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  /* background: #2544f4; */
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
/* .ant-carousel >>> .slick-track{z-index: 100 !important;} */
</style>